@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@600;700&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Open Sans', serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  font-weight: 400;
  color: #2d2d2d;
}

h2, h3, h4, h5, h6, .card-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

h1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
}
